
function CetakImageDua(){
    const kosong = "/UndanganCetak/"
    const idImage = `/UndanganCetak/#content`
    return(
        <div className="undangancetakWrapper">

        <div className="undangancetakbox">
            <div className="untukpaddingcetakbox">
            <div>              
                <a href={`#${idImage}`} className='imgCH'>
                    <img id='gambarTarget' src="./imageGroup/undangancetak/lipat3/LIPAT-3-DAN-AMPLOP-09.jpg"></img>
                </a>
                <div className='overlay' id={idImage}>
                    <div className="over-inside">
                    <a href={`#${kosong}`} className='KeluarImage'>CLOSE</a>
                    <img src="./imageGroup/undangancetak/lipat3/LIPAT-3-DAN-AMPLOP-09.jpg"></img>
                    <div id ={`#${kosong}`}></div>
                    </div>
                </div>
            </div>


                <div>
                    <div className="undangancetakboxtext">
                        <h1>KODE : CETAK</h1>
                    </div>
                    <div>
                        <p>Ukuran kertas A4</p>
                        <p>Jenis Kertas : Jasmin Glitter 200 gsm</p>
                        <p>Design Full color</p>
                    </div>
                </div>
            </div>
        </div>


        </div>
    )
}
export default CetakImageDua;