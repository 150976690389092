import { useEffect } from 'react';
import '../../cssGroup/UndanganCetak.css'
import { useState } from 'react';

function TextBerubah (){
    const [toggle, setToggle] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setToggle(prevToggle => !prevToggle);
        }, 4000); // 4000 ms = 4 detik

        return () => clearInterval(interval); // Bersihkan interval saat komponen di-unmount
    }, []);



        useEffect(() => {
            
            const messageElement = document.getElementById('message');
            if (toggle) {
                messageElement.innerHTML = `<div class="footerUCtext">
                    <h1 >FREE</h1>
                    <h1 class="hiddenH1">_</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-1s">L</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-2s">A</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-3s">B</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-4s">E</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-4s">L</h1>
                    <h1 class="hiddenH1">_</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-5s">N</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-5s">A</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-5s">M</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-5s">A</h1>
                </div>`
            } else {
                messageElement.innerHTML = `<div class="footerUCtext">
                    <h1 >FREE</h1>
                    <h1 class="hiddenH1">_</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-1s">P</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-2s">l</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-3s">a</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-4s">s</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-4s">t</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-4s">i</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-4s">k</h1>
                    <h1 class="hiddenH1">_</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-5s">O</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-5s">p</h1>
                    <h1 class="animate__animated animate__flipInX animate__delay-5s">p</h1>
                   
                </div>`;
            }
     
        }, [toggle])
        
    return(
        <>
        <div id="message">
        <div className="footerUCtext">
                    <h1 >FREE</h1>
                    <h1 className="hiddenH1">_</h1>
                    <h1>P</h1>
                    <h1>l</h1>
                    <h1>a</h1>
                    <h1>s</h1>
                    <h1>t</h1>
                    <h1>i</h1>
                    <h1>k</h1>
                    <h1 className="hiddenH1">_</h1>
                    <h1>O</h1>
                    <h1>p</h1>
                    <h1>p</h1>
                   
                </div>
        </div>        
        </>
    )
}

export default TextBerubah;