import React from 'react'
import ImageGaleri from './ImageGaleri'

const SesiEmpat = () => {
    return (
        <div className='sesiempat-wrapper'>
            <div className='imageGaleri-inside'>
                <div className='sesiempat-text'>
                    <h1 className='sesiempat-texth1'>Save the Date</h1>
                    <div className='box-container-sesi4'>
                        <div className='sesi4-white-box'>
                            <h1>00</h1>
                            <p>Days</p>
                        </div>

                        <div className='sesi4-white-box'>
                            <h1>00</h1>
                            <p>Hours</p>
                        </div>

                        <div className='sesi4-white-box'>
                            <h1>00</h1>
                            <p>Minutes</p>
                        </div>

                        <div className='sesi4-white-box'>
                            <h1>00</h1>
                            <p>Second</p>
                        </div>
                    </div>
                    <div>
                        <button className='btn-sesi4'>
                            <h3>Save The Date</h3>
                        </button>
                    </div>
                </div>
                <div className='black-boxsesiempat'></div>
                <ImageGaleri />
            </div>

        </div>
    )
}

export default SesiEmpat
