import '../../cssGroup/UndanganCetak.css'
function ButtonUndangan({NameButton, onClick}){
    return (
        <div className="btn-undangancetakWrapper">
            <div className="btn-undangancetak">
                <button onClick={() => onClick()}>
                    {NameButton} 
                </button>
            </div>
        </div>
    )
}

export default ButtonUndangan;