import React, { useState, useEffect, useRef } from 'react';

const SesiDuabelas = () => {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5; // Definisikan batas item per halaman

    useEffect(() => {
        fetch('http://localhost:8000/')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    // Sort data
    const sortedData = [...data].sort((a, b) => b.id - a.id);

    // Pagination calculations
    const totalPages = Math.ceil(sortedData.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const goToPage = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const formRef = useRef(null);
    const namaRef = useRef(null);
    const ucapanRef = useRef(null);
    const konfirmasiRef = useRef(null);

    useEffect(() => {
        const handleSubmit = (event) => {
            event.preventDefault();

            const nama = namaRef.current.value;
            const ucapan = ucapanRef.current.value;
            const konfirmasiKehadiran = konfirmasiRef.current.value;

            const formData = {
                nama: nama,
                ucapan: ucapan,
                konfirmasi_kehadiran: konfirmasiKehadiran
            };

            fetch('http://localhost:8000/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                    // Optionally refresh data after submission
                    return fetch('http://localhost:8000/')
                        .then(response => response.json())
                        .then(data => setData(data))
                        .catch(error => console.error('Error fetching data:', error));
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        };

        const formElement = formRef.current;
        if (formElement) {
            formElement.addEventListener('submit', handleSubmit);
        }

        return () => {
            if (formElement) {
                formElement.removeEventListener('submit', handleSubmit);
            }
        };
    }, []);

    return (
        <div>
            <div className="sesitiga-wrapper">
                <div className='sesitiga-container'>
                    <div className="sesitiga-text">
                        <div className='sesitiga-box-sesi12'>
                            <div className='s12-container'>
                                <div className='s12-header'>
                                    <img src='./imageGroup/Preview/SB-inisial-black.png' alt="Header" />
                                    <h1>Best Wishes</h1>
                                    <h2>SEND YOUR BEST WISHES</h2>
                                </div>
                                <div className='s12-konten'>
                                    <p>{data.length} Comments</p>
                                    <div className='kehadiran-btn-group'>
                                        <button className='kehadiran-btn'>
                                            <p>4</p>
                                            <p>Hadir</p>
                                        </button>

                                        <button className='kehadiran-btn'>
                                            <p>4</p>
                                            <p>Tidak Hadir</p>
                                        </button>
                                    </div>
                                    <form ref={formRef} id="data-form" method="POST">
                                        <input ref={namaRef} className='isitextform' type="text" id="nama" name="nama" placeholder="Nama" />
                                        <br /><br />
                                        <input ref={ucapanRef} className='isitextform' type="text" id="ucapan" name="ucapan" placeholder="Ucapan" />
                                        <br /><br />
                                        <select ref={konfirmasiRef} className='isitextform' id="konfirmasi_kehadiran" name="konfirmasi_kehadiran">
                                            <option value="" disabled selected>Konfirmasi Kehadiran</option>
                                            <option value="Hadir">Hadir</option>
                                            <option value="Tidak Hadir">Tidak Hadir</option>
                                        </select>
                                        <div className='komen-submit-div'>
                                            <button className='komen-submit' type="submit">Kirim</button>
                                        </div>
                                    </form>
                                    <div className='komen-section'>
                                        <div id='box-komen'>
                                            {currentItems.map((item) => (
                                                <div key={item.id} className="box-isi">
                                                    <p><b>{item.nama}</b></p>
                                                    <p>{item.ucapan}</p>
                                                    <p>{item.konfirmasi_kehadiran}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="pagination">
                                            <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                                            {Array.from({ length: totalPages }, (_, index) => (
                                                <button
                                                    key={index + 1}
                                                    onClick={() => goToPage(index + 1)}
                                                    className={currentPage === index + 1 ? 'active' : ''}
                                                >
                                                    {index + 1}
                                                </button>
                                            ))}
                                            <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SesiDuabelas;
