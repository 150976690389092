import React, { useEffect } from 'react'
import { useRef } from 'react';

const SesiDua = () => {
    const boxRef = useRef(null);
    const boxRef2 = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate__animated', 'animate__fadeInLeftBig');
                }
            });
        }, { threshold: 0.3 }); // Atur threshold jika diperlukan

        const observer2 = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate__animated', 'animate__bounceInUp');
                }
            });
        }, { threshold: 0.9 }); 

        if (boxRef.current) {
            observer.observe(boxRef.current);
        }
        if (boxRef2.current) {
            observer2.observe(boxRef2.current);
        }

        return () => {
            if (boxRef.current) {
                observer.unobserve(boxRef.current);
            }
            if (boxRef2.current) {
                observer2.unobserve(boxRef.current);
            }
        };
    }, []);

    return (

        <div className="sesidua-wrapper" id='sesidua-wrapper'>
            <div className='sesidua-container'>
                <div className="sesidua-text">
                    <img src="./imageGroup/Preview/merak2.png"></img>
                    <h1 ref={boxRef}>We Found Love</h1>
                    <p ref={boxRef2}>وَمِنْ اٰيٰتِهٖٓ اَنْ خَلَقَ لَكُمْ مِّنْ اَنْفُسِكُمْ اَزْوَاجًا لِّتَسْكُنُوْٓا اِلَيْهَا وَجَعَلَ بَيْنَكُمْ مَّوَدَّةً وَّرَحْمَةً ۗاِنَّ فِيْ ذٰلِكَ لَاٰيٰتٍ لِّقَوْمٍ يَّتَفَكَّرُوْنَ</p>
                    <p ref={boxRef2}>“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”</p>
                    <p className='ayatText'><strong>Ar-Rum : 21</strong></p>
                    <hr></hr>
                </div>
            </div>
        </div>

    )
}

export default SesiDua
