import Navigasi from "../component/indexComponent/navigasi";
import ButtonUndangan from "../component/UndanganCetak/ButtonUndangan";
import BigButtonUndangan from "../component/UndanganCetak/BigButtonUndangan";
import CetakImage from "../component/UndanganCetak/Lipat 2/CetakImage";
import FooterUndanganctk from "../component/UndanganCetak/FooterUndanganctk";
import CetakImageSlide from "../component/UndanganCetak/Lipat 2/CetakImageSlide";
import LipatDua from "../component/UndanganCetak/Lipat 2/LipatDua";
import CetakImage_l3 from "../component/UndanganCetak/CetakImage_l3";
import CetakImage_l3_Slide from "../component/UndanganCetak/CetakImage_l3_Slide";
import CetakImage_Amp from "../component/UndanganCetak/CetakImage_Amp";
import TestLb from "../component/UndanganCetak/TestLb";
import CetakImage_Amp2 from "../component/UndanganCetak/CetakImage_Amp";
import CetakImage_AmpDua from "../component/UndanganCetak/CetakImage_AmpDua";
import LipatTiga from "../component/UndanganCetak/Lipat 3/LipatTiga";
import AmplopUpdate from "../component/UndanganCetak/amplop/AmplopUpdate";
import { useEffect, useState } from "react";

function UndanganCetak() {

  const [tampilKomponen, setTampilKomponen] = useState(<LipatDua />)
  const [gantiBesar, setGantiBesar] = useState(2)

  function gantiKomp(x) {
    setGantiBesar(x)
    cekIF(x)
  }
  function cekIF(x) {
    if (x == 2) {
      setTampilKomponen(<LipatDua />)
    } else if (x == 3) {
      setTampilKomponen(<LipatTiga />)
    } else if (x == "AMPLOP") {
      setTampilKomponen(<AmplopUpdate />)
    }
  }




  return (
    <div>
      <Navigasi />
      <div className='upImage'>
        <img src="./imageGroup/undangancetak/CETAK.jpg" alt='dag'></img>

      </div>
      <div className='headText'>
        <h2>KATALOG UNDANGAN WEBSITE</h2>
        <p>Pilih model sesuai kategori dengan menekan tombol di bawah</p>
      </div>

      <div>
        <div className="ButtonUndanganContainer">
          <div className="ButtonUndangankonten">
            <ButtonUndangan NameButton="Lipat 2" onClick={() => gantiKomp(2)} />
            <ButtonUndangan NameButton="Lipat 3" onClick={() => gantiKomp(3)} />
            <ButtonUndangan NameButton="Amplop" onClick={() => gantiKomp("AMPLOP")} />
          </div>
        </div>

        <div className="BigButtonUndanganwrapper">
          <div className="BigButtonUndangankonten">
            <BigButtonUndangan modelLipat={gantiBesar} />
          </div>
        </div>
      </div>

      <div>
        <div className="cetakimg-wrapper">
          <div className="cetakimg-konten">
            {tampilKomponen}
          </div>
        </div>
      </div>

      <div className="bungkusHR22">
        <hr></hr>
      </div>

      <div>
        <div>
          <FooterUndanganctk />
        </div>
      </div>




    </div>
  )
}

export default UndanganCetak;