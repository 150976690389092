import React from 'react'

const SesiTujuh = () => {
    return (
        <div>
            <div className='sesi7-wrapper'>
                <div className='sesi7-container'>
                    <div className='sesi7-abs'>
                        <h1>Our Love Story</h1>
                        <p><i>Every Love Story Is Beautiful, but Ours is My Favorite</i></p>
                    </div>
                    <img src='./imageGroup/Preview/WhatsApp-Image-2023-03-01-at-21.43.55-1.jpeg'></img>
                </div>
            </div>

        </div>
    )
}

export default SesiTujuh
