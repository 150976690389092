import { Link } from "react-router-dom";


function CardHp ({alamat, nama}){
    const kosong = "/home/"
    const idImage = `/home/#${nama}`
    
    
    return (
        <div className='cardHPwrapper'>
            

            <div>              
                <a href={`#${idImage}`} className='imgCH'>
                    <img id='gambarTarget' src={alamat}></img>
                </a>
                <div className='overlay' id={idImage}>
                    <div className="over-inside">
                    <a href={`#${kosong}`} className='KeluarImage'>CLOSE</a>
                    <img src={alamat}></img>
                    <div id ={`#${kosong}`}></div>
                    </div>
                </div>
            </div>

            <div className="btnCH">
            <Link to="/PreviewItem" className="preview" target="_blank">
                <button>
                    Preview
                </button>
                </Link>
            </div>

        </div> 
    )
}

export default CardHp;