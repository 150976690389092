

function BigButtonUndangan({modelLipat}){
    return(
        <div className="BigButtonUndangan-wrapper">
            <div className="BigButtonUndanganContainer">
                <div className="BigButtonUndanganContent">
                    <h1>CETAK MODEL LIPAT {modelLipat}</h1>
                </div>
            </div>
        </div>
    )
}

export default BigButtonUndangan;