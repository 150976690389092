import TextBerubah from "./TextBerubah";

function FooterUndanganctk(){
    return(
        <div>

            <div className="footerUC-wrapper">
                <div className="footerUCimage">
                    <img src="./imageGroup/undangancetak/PRICELIST-UNDANGAN-CETAK.png"></img>
                </div>
                <div className="settingRight">
                    <TextBerubah/>
                    <div className="footerUCbutton">
                        <button>
                            Pesan Sekarang
                        </button>
                    </div>
                </div>
            </div>




        </div>

    )
}
export default FooterUndanganctk;