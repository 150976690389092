import SesiSatu from '../component/PreviewItem/Sesi1/SesiSatu';
import SesiDelapan from '../component/PreviewItem/SesiDelapan/SesiDelapan';
import SesiDua from '../component/PreviewItem/SesiDua';
import SesiDuabelas from '../component/PreviewItem/SesiDuabelas';
import SesiEmpat from '../component/PreviewItem/SesiEmpat/SesiEmpat';
import SesiEnam from '../component/PreviewItem/SesiEnam';
import SesiLima from '../component/PreviewItem/SesiLima';
import SesiSembilan from '../component/PreviewItem/SesiSembilan';
import SesiSepuluh from '../component/PreviewItem/SesiSepuluh';
import SesiTiga from '../component/PreviewItem/SesiTiga';
import SesiTigabelas from '../component/PreviewItem/SesiTigabelas';
import SesiTujuh from '../component/PreviewItem/SesiTujuh';
import UpgroundLayer from '../component/PreviewItem/UpgroundLayer';
import '../cssGroup/PreviewItem.css';
import { useEffect, useRef, useState } from 'react';



function PreviewItem() {
    const audioRef = useRef(null);
    const [playMusic, setPlayMusic] = useState(true);
    const [hideDiv, setHideDiv] = useState(false);

    useEffect(() => {
        const audioElement = audioRef.current;

        if (audioElement) {
            const handlePlay = () => {
                console.log('Audio is playing');
            };
            const handlePlay2 = () => {
                console.log('Audio is paused');
            };

            // Menambahkan event listener
            audioElement.addEventListener('play', handlePlay);
            audioElement.addEventListener('pause', handlePlay2);
            // Cleanup listener saat komponen di-unmount
            return () => {
                audioElement.removeEventListener('play', handlePlay);
                audioElement.addEventListener('pause', handlePlay2);
            };
        }
    }, []);

    const playAudio = () => {
        setPlayMusic(!playMusic)
        setHideDiv(true);
        if (playMusic == true && audioRef.current) {
            audioRef.current.play();
        } else if (playMusic == false) {
            audioRef.current.pause();
        }

    };

    useEffect(() => {
        const tutupLayerFixed = document.getElementById('fixed-layer2id');
        function hilangkanLayer(){
            tutupLayerFixed.style.zIndex = 0
        }
        if (hideDiv == true){
            hilangkanLayer();
        }
    },[])

    return (
        <div>
            <div id='tbl-musik-wrapper' className='tbl-musik-wrapper'>
                <button className='tbl-musik' onClick={playAudio}>
                    M
                </button>
            </div>
            <div id='fixed-layer2id' className='fixed-layer'>

                <div className='fixed-layer2'>
                    <UpgroundLayer onClick={playAudio} />
                    <div>
                        <audio ref={audioRef}>
                            <source src='./audio/someonetolava.m4a' type="audio/mpeg" />
                        </audio>
                    </div>
                </div>

            </div>

            <div>
                <div className='batasss'>
                    <SesiSatu />
                </div>
                {/* sesi2 */}
                <div>
                    <SesiDua />
                </div>
                {/* sesi3 */}
                <div>
                    <SesiTiga />
                </div>

                {/* sesi4 */}
                <div>
                    <SesiEmpat />
                </div>

                {/* sesi5 */}
                <div>
                    <SesiLima />
                </div>

                {/* sesi6 */}
                <div>
                    <SesiEnam />
                </div>

                {/* sesi7 - sama seperti 5 */}
                <div>
                    <SesiTujuh />
                </div>

                {/* sesi8 */}
                <div>
                    <SesiDelapan />
                </div>
                {/* sesi9 */}
                <div>
                    <SesiSembilan />
                </div>

                {/* sesi10 */}
                <div className='sesi10-sesi11-wrapper'>
                    <SesiSepuluh />
                </div>

                <div>
                    <SesiDuabelas />
                </div>

                <div>
                    <SesiTigabelas />
                </div>

            </div>

        </div>







    )
}

export default PreviewItem;