import '../../../cssGroup/UndanganCetak.css'
import CetakImageDua from './CetakImageDua';
import CetakImage from './CetakImage';


function LipatTiga(){
    return(
        <div>

            <div className="ctkimg-wrapper">
                <div className="ctkimg-container">
                    
                    <CetakImage LipatDua="layer 1" />
                    <CetakImageDua />
                    <CetakImage LipatDua="layer 2" />
                </div>

            </div>

        </div>
    )
}

export default LipatTiga;