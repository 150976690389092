import React from 'react'
import { useState, useEffect } from 'react';
import PreviewItem from '../../pages/PreviewItem';

const UpgroundLayer = ({onClick}) => {
    const [imageUrl, setImageUrl] = useState('./imageGroup/Preview/bg/BG-COVER.jpg'); // Gambar default

    useEffect(() => {
        // Fungsi untuk memeriksa ukuran jendela dan memperbarui URL gambar
        const handleResize = () => {
            if (window.innerWidth > 800) {
                setImageUrl('./imageGroup/Preview/TEMA-32-DESIGN-DESKTOP.jpg'); // URL gambar untuk ukuran kecil
            } else {
                setImageUrl('./imageGroup/Preview/bg/BG-COVER.jpg'); // URL gambar untuk ukuran besar
            }
        };

        // Menambahkan listener untuk event resize
        window.addEventListener('resize', handleResize);

        // Memanggil handleResize saat pertama kali memuat
        handleResize();

        // Cleanup listener saat komponen di-unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(()=>{
        const tmblTutup = document.getElementById('btn-open-invit');
        const tgtTututp = document.getElementById('fixed-layer2id')
        const sesiDua = document.getElementById('sesidua-wrapper')
        
        const tutupDiv = () => {
                tgtTututp.style.transform = "translateY(100%)";
                tgtTututp.style.transition = "500ms"
                tgtTututp.style.zIndex = -5;
               sesiDua.style.zIndex = 20;
        }
        tmblTutup.addEventListener('click', tutupDiv)

        return () => {
            tmblTutup.removeEventListener('click', tutupDiv)
        }
    })
    
    return (
        <div>
            <div>              
                <div id='background-wrapper' className='background-wrapper'>
                    <div className='konten-bgron'>
                        <img src='./imageGroup/Preview/SB-inisial.png'></img>
                        <div className='text-bgron'>
                            <h1>Shintia & Bima</h1>
                            <p>Dear :</p>
                            <div className='open-invit'>
                                <button id='btn-open-invit' onClick={() => onClick()}>
                                    Open Invitation
                                </button>
                            </div>
                        </div>
                    </div>
                    <img className='background' src={imageUrl}>
                    </img>
                </div>

            </div>

        </div>
    )
}

export default UpgroundLayer
