import { useEffect, useState } from "react";


function CetakImageSlide( {isicurrent} ) {
    const kosong = "/UndanganCetak/"
    const idImage = `/UndanganCetak/#content-Slide`
    const [alamatPlace, setAlamatPlace] = useState("./imageGroup/undangancetak/lipat3/LIPAT-3-TEMA-18-NON-FOTO.jpg")

    console.log(isicurrent)
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const slides = document.getElementsByClassName(isicurrent);

        // Mengatur semua slides untuk disembunyikan
        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }

        // Menampilkan slide saat ini
        if (slides.length > 0) {
            slides[currentIndex].style.display = "block";
        }
    }, [currentIndex]);

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? 1 : prevIndex - 1));
        if(alamatPlace == "./imageGroup/undangancetak/lipat3/LIPAT-3-TEMA-18-NON-FOTO.jpg"){
            setAlamatPlace("./imageGroup/undangancetak/lipat3/LIPAT-3-TEMA-18.Jpg")
        } else {
            setAlamatPlace("./imageGroup/undangancetak/lipat3/LIPAT-3-TEMA-18-NON-FOTO.jpg")
        }
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 1 ? 0 : prevIndex + 1));
        if(alamatPlace == "./imageGroup/undangancetak/lipat3/LIPAT-3-TEMA-18-NON-FOTO.jpg"){
            setAlamatPlace("./imageGroup/undangancetak/lipat3/LIPAT-3-TEMA-18.Jpg")
        } else {
            setAlamatPlace("./imageGroup/undangancetak/lipat3/LIPAT-3-TEMA-18-NON-FOTO.jpg")
        }
    };




    return (
        <div>
            <div className="slideshow-container">

                <div className={`${isicurrent} fade`}>
                    <div className="numbertext">1 / 2</div>

                    <a href={`#${idImage}`} className='imgCH'>
                        <img id='gambarTarget' src={alamatPlace}></img>
                    </a>

                    <div className="text">Versi 1</div>
                </div>

                <div className={`${isicurrent} fade`}>
                    <div className="numbertext">2 / 2</div>

                    <a href={`#${idImage}`} className='imgCH'>
                        <img id='gambarTarget' src={alamatPlace}></img>
                    </a>

                    <div className="text">Versi 2</div>
                </div>

                <div className='overlay' id={idImage}>
                    <div className="over-inside">
                        <div className="next-prex-overlay">
                        <a className="prev" onClick={handlePrevClick}>&lt;</a>
                        <a className="next" onClick={handleNextClick}>&gt;</a>
                        </div>
                        <a href={`#${kosong}`} className='KeluarImage'>CLOSE</a>
                        <img src={alamatPlace}></img>
                        <div id={`#${kosong}`}></div>
                    </div>
                </div>

                <a className="prev" onClick={handlePrevClick}>&lt;</a>
                <a className="next" onClick={handleNextClick}>&gt;</a>

            </div>
        </div>

    )
}

export default CetakImageSlide;