import React from 'react'

const SesiEnam = () => {
  return (
    <div className='sesi6-wrapper'>
        <div className='sesi6-container'>
            <div className='sesi6-bg'>
                <img src='./imageGroup/Preview/tema-32-back-land.jpg'></img>
            <div className='sesi6-absolute-konten'>
                <h1>Live Streaming</h1>
                <p>Temui kami secara virtual untuk menyaksikan acara pernikahan kami yang akan disiarkan langsung dengan menekan tombol di bawah ini</p>
                <div>
                    <button className='btn-sesienam'>@deafebriana_</button>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default SesiEnam
