import '../../cssGroup/filterInsta.css'
import '../../cssGroup/LighBox.css'


function CardInsta ({numberImg}){
    const kosong = "/FilterInsta/"
    const idImage = `/FilterInsta/#content`
    return(
        <div className="filterCardWrapper">
            <div className="textFilterInsta">
                <h2>Filter {numberImg}</h2>
            </div>
            <div className='cardHPwrapper'>
            

            <div>              
                <a href={`#${idImage}`} className='imgCardFilterInsta'>
                    <img id='gambarTarget' src="./imageGroup/filter/conten.jpg"></img>
                </a>
                <div className='overlay' id={idImage}>
                    <div className="over-inside">
                    <a href={`#${kosong}`} className='KeluarImage'>CLOSE</a>
                    <img src="./imageGroup/filter/conten.jpg"></img>
                    <div id ={`#${kosong}`}></div>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
}

export default CardInsta;