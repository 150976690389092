import React, { useState, useEffect } from 'react';
export default function SesiSatu() {
    const [imageUrl, setImageUrl] = useState('./imageGroup/Preview/bg/DESIGN-PHONE-2.jpg'); // Gambar default

    useEffect(() => {
        // Fungsi untuk memeriksa ukuran jendela dan memperbarui URL gambar
        const handleResize = () => {
            if (window.innerWidth > 800) {
                setImageUrl('./imageGroup/Preview/TEMA-32-DESIGN-DESKTOP.jpg'); // URL gambar untuk ukuran kecil
            } else {
                setImageUrl('./imageGroup/Preview/bg/DESIGN-PHONE-2.jpg'); // URL gambar untuk ukuran besar
            }
        };

        // Menambahkan listener untuk event resize
        window.addEventListener('resize', handleResize);

        // Memanggil handleResize saat pertama kali memuat
        handleResize();

        // Cleanup listener saat komponen di-unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div className="sesisatu-wrapper">
            <div className='relatimage2'>
                <img className='flowerUp' src='./imageGroup/Preview/flower.gif'></img>
            </div>
            <div className="relatimage">
                <img className='upperImage' src='./imageGroup/Preview/giphy.gif'></img>
            </div>
            <div className="image-container">
                <img src="./imageGroup/Preview/PHOTO-DAN-COVER.png"></img>
                <div className="sesisatu-text">
                    <h1>THE WEDDING OF</h1>
                    <h1 className="Sht-Bima">Shintia & Bima</h1>
                    <h2>03 . 03 . 2023</h2>
                </div>
            </div>

            <div className='back-div'>
                <img className='BackImg' src={imageUrl}></img>
            </div>
        </div>

    )
}