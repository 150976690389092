import logo from '../../logo.svg';
import "../../cssGroup/navigasi.css";
import { Link } from 'react-router-dom';

function Navigasi() {
    return (
        <div className='navWrapper'>
            <div className='navBar'>

                <nav><Link to="/" target="_self">
                    <img src={logo} alt='logo ku' className='nav-logo'></img></Link>
                </nav>

            </div>
        </div>

    )
}

export default Navigasi;