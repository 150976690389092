import React from 'react'
import { ToggleButton } from 'react-bootstrap'

const SesiSembilan = () => {
    return (
        <div>
            <div className="sesitiga-wrapper">
                <div className='sesitiga-container'>
                    <div className="sesitiga-text9">
                        <div className='sesitiga-box9'>
                            <div className='sesi9-form'>
                                <img src='./imageGroup/Preview/WhatsApp-Image-2023-03-01-at-21.43.56-1-682x1024.jpeg'></img>
                                <div className='atur-form-s9'>
                                    <h1>RSVP</h1>
                                    <p>Konfirmasi kedatangan anda</p>

                                    <form className='form-kirim' action="/action_page.php" target="_blank">

                                        <label htmlFor="fname">Nama</label><br></br>
                                        <input className='isitextform' type="text" id="fname" name="fname"></input><br></br>

                                        <label htmlFor="fjumlah">Jumlah</label><br></br>
                                        <input className='isitextform' type="text" id="fjumlah" name="fjumlah"></input><br></br>

                                        <label htmlFor="fpesan">Pesan</label><br></br>
                                        <textarea className='isitextform3' id="comments" name="comments" rows="5" cols="40">
                                        </textarea>
                                        <br></br>

                                        <div>
                                            <input type="radio" id="html" name="fav_language" value="HTML"></input>
                                            <label htmlFor="html">Iya, saya akan datang</label><br></br>
                                        </div>

                                        <div>
                                            <input type="radio" id="css" name="fav_language" value="CSS"></input>
                                            <label htmlFor="css">Saya Masih Ragu</label><br></br>
                                        </div>

                                        <div>
                                            <input type="radio" id="javascript" name="fav_language" value="JavaScript"></input>
                                            <label htmlFor="javascript">Maaf, saya tidak bisa datang</label>
                                        </div>
                                        <p>Konfirmasi</p>
                                        <input className='btnform' type="submit" value="Reservasi Via Whatsapp"></input>

                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default SesiSembilan
