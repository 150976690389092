import React from 'react'

const SesiLima = () => {
    return (

        <div>
            <div className="sesitiga-wrapper">
                <div className='sesitiga-container'>
                    <div className="sesitiga-text5">
                        <div className='sesilima-box'>
                            <div className='sesilima-headertext'>
                                <p>Dengan memohon Rahmat & Ridho Allah SWT, kami bermaksud mengundang Bapak/Ibu/Saudara/i</p>
                                <p>untuk menghadiri acara pernikahan putra-putri kami yang Insya Allah akan diselenggarakan pada:</p>
                            </div>
                            <hr></hr>

                            <h1 className='akad-sesi5'>Akad Nikah</h1>
                            <div className='akad-sesi5-isi'>
                                <h2>Jumat</h2>
                                <h2>03 . 03 . 2023</h2>
                                <p>Pukul 08.00 WIB</p>
                            </div>
                            <img className='sesitiga-icon' src='./imageGroup/sec3/01.png'></img>
                            <div className='sesilima-text-kediaman'>
                                <p>Kediaman Mempelai Wanita</p>
                                <p>Prumbanan RT 4/ RW 4 Purwojati, Kec. Kertek</p>
                                <p>Kab. Wonosobo – Jawa Tengah (56371)</p>
                            </div>
                            <button>
                                Google Maps
                            </button>
                            <div className='sesilima-foto'>
                                <img src='./imageGroup/Preview/WhatsApp-Image-2023-03-01-at-21.43.55-1.jpeg'></img>
                            </div>
                            <h1 className='akad-sesi5'>Resepsi</h1>
                            <div className='akad-sesi5-isi'>
                                <h2>Jumat</h2>
                                <h2>03 . 03 . 2023</h2>
                            </div>
                            <p>
                                Pukul 14.00 WIB</p>
                            <img className='sesitiga-icon' src='./imageGroup/sec3/01.png'></img>
                            <div className='sesilima-text-kediaman'>
                                <p>
                                    Kediaman Mempelai Wanita</p>
                                <p>Prumbanan RT 4/ RW 4 Purwojati, Kec. Kertek</p>
                                <p>Kab. Wonosobo – Jawa Tengah (56371)</p>
                            </div>
                            <button>Google Maps</button>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default SesiLima
