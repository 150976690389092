import React from 'react'

const CardDelapan = ({sumberFile}) => {
    return (
        <div className='Card8-wrapper'>
            <div className='Card8-container'>
                <div className='Card8-img-header'>
                    <img src={sumberFile}></img>
                </div>
                <h1>Chapter One</h1>
                <div className='sesi8-text'>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
                </div>
                <hr></hr>
            </div>
        </div>
    )
}

export default CardDelapan
