import React from 'react'
import CardDelapan from './CardDelapan'
import MultiImage from './MultiImage'
import GaleriEmpat from './GaleriEmpat'

const SesiDelapan = () => {
    return (
        <div className='sesi8-wrapper'>
            <div className='sesi8-wrapper1'>
            <div className='sesi8-wrapper2'>
                <div className='sesi8-container-1'>
                    <CardDelapan sumberFile='./imageGroup/Preview/ls3.jpg' />
                </div>
                <div className='sesi8-container-1'>
                    <CardDelapan sumberFile='./imageGroup/Preview/ls2.jpg' />
                </div>
                <div className='sesi8-container-1'>
                    <CardDelapan sumberFile='./imageGroup/Preview/ls1.jpg' />
                </div>
                </div>
            </div>
            <div className='hr-mid-s8'>
                <hr></hr>
            </div>

            <div className='sesi8-container-1'>
                <div className='sesi8-padding'>
                    <MultiImage />
                </div>
                <div className='sesi8-container-2'>
                    <div className='sesi8-gal4'>
                        <div className='sesi8-gal4-isi'><GaleriEmpat /></div>
                        <div className='sesi8-gal4-isi'><GaleriEmpat /></div>
                        <div className='sesi8-gal4-isi'><GaleriEmpat /></div>
                        <div className='sesi8-gal4-isi'><GaleriEmpat /></div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SesiDelapan
