import React from 'react'

const SesiTiga = () => {
    return (
        <div>
            <div className="sesitiga-wrapper">
                <div className='sesitiga-container'>
                    <div className="sesitiga-text">
                        <div className='sesitiga-box'>
                            <div className='sesi3-gap-setting'>
                                <div className='sesitiga-headerText'>
                                    <p>Bismillahirrahmanirrahiim</p>
                                    <p>Dengan memohon Rahmat & Ridho Allah SWT, kami bermaksud mengundang Bapak/Ibu/Saudara/i</p>
                                    <p>untuk menghadiri acara pernikahan putra-putri kami</p>
                                </div>
                                <div className='sesi3-atur-flex'>
                                    <div className='sesitiga-profilecewek'>
                                        <img className='sesitiga-profile' src='
./imageGroup/Preview/profilcewek-1024x1016.png'></img>
                                        <div className='text-sesi3-set'>
                                            <h1>Shintia Putri</h1>
                                            <div className='sesitiga-ortu'>
                                                <p>Putri dari</p>
                                                <p>"Bapak Herry Setiawan & Ibu Rina Marlina"</p>
                                            </div>
                                        </div>
                                        <img className='sesitiga-icon' src='./imageGroup/sec3/01.png'></img>

                                    </div>

                                    <div className='sesitiga-dan'>
                                        <img src='./imageGroup/Preview/dan-bunga.png'></img>
                                    </div>

                                    <div className='sesitiga-profilecowok'>
                                        <img className='sesitiga-profile' src='./imageGroup/Preview/profilcowok-1024x1016.png'></img>
                                        <div className='text-sesi3-set'>
                                            <h1>Bhima Putra</h1>
                                            <div>
                                                <p className='sesitiga-ortu'>Putra dari</p>
                                                <p>"Bapak Herry Setiawan & Ibu Rina Marlina"</p>
                                            </div>
                                        </div>
                                        <img className='sesitiga-icon' src='./imageGroup/sec3/01.png'></img>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default SesiTiga
