import CetakImage from "./CetakImage";
import CetakImageSlide from "./CetakImageSlide";
import '../../../cssGroup/UndanganCetak.css'
import CetakImage_AmpDua from "../CetakImage_AmpDua";
import CetakImage_Amp from "../CetakImage_Amp";
import CetakImageDua from "./CetakImageDua";

function AmplopUpdate(){
    return(
        <div>

            <div className="ctkimg-wrapper">
                <div className="ctkimg-container">
                    
                    <CetakImage LipatDua="layer 1" getnama1="gambar1" getnama2="gambar2"/>
                    <CetakImageDua />
                    <CetakImage LipatDua="layer 2" getnama1="gambar3" getnama2="gambar4"/>
                </div>

            </div>

        </div>
    )
}

export default AmplopUpdate;