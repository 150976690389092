import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

const SesiTigabelas = () => {
    const [imageSrc, setImageSrc] = useState('./imageGroup/Preview/bg/DESIGN-PHONE-2.jpg'); // Default image for screens < 1600px

  const handleResize = () => {
    if (window.innerWidth > 1500) {
      setImageSrc('./imageGroup/Preview/DESIGN-DESKTOP333.png');
    } else {
      setImageSrc('./imageGroup/Preview/bg/DESIGN-PHONE-2.jpg');
    }
  };

  useEffect(() => {
    // Set initial image based on current window size
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return (
        <div className="sesisatu-wrapper13">

            <div className="image-container13">
                <div className='image13-container'>
                    <img className='last-img' src='./imageGroup/Preview/PHOTO-DAN-COVER.png'></img>
                </div>
                <div className="sesisatu-text13">
                    <div className='sesi13-manage'>
                        <h1>Terima Kasih</h1>
                        <div className='last-text'>
                            <p>Merupakan suatu kehormatan dan kebahagiaan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan memberikan do’a restunya untuk keberkahan pernikahan kami.</p><br></br>
                            <p>Atas do’a dan restunya,</p>
                            <p>kami ucapkan terima kasih.</p>
                        </div>
                        <h1>Shintia & Bima</h1>
                    </div>
                </div>
            </div>

            <div className='image13-cont'>
                <img className='BackImg13' src={imageSrc}></img>
            </div>
        </div>

    )
}

export default SesiTigabelas
