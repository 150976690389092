import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

const MultiImage = () => {
    let [naikSatu, setNaikSatu] = useState(0);
    useEffect(() => {

        let dots = document.getElementsByClassName("dot");
        let slides = document.getElementsByClassName("mySlides");

        function RagnaRok() {
            if (naikSatu > 1) {
                naikSatu = 0
            } else if(naikSatu <0){
                naikSatu = 1
            }
            
            for (let i = 0; i < slides.length; i++) {
                slides[i].style.display = "none";  
              }
            slides[naikSatu].style.display = "block";
            
        }
        RagnaRok();

    }, [naikSatu])





    return (
        <div>
            <div className="slideshow-container">

                <div className="mySlides fade">
                    <div className="numbertext">1 / 2</div>
                    <img className='sesi8-padding'
                        src="./imageGroup/Preview/WhatsApp-Image-2023-03-01-at-21.43.57.jpeg"
                        alt="Deskripsi gambar" style={{ width: '100%' }} />
                    <div className="text">Versi 1</div>
                </div>

                <div className="mySlides fade">
                    <div className="numbertext">2 / 2</div>
                    <img className='sesi8-padding'
                        src="./imageGroup/Preview/WhatsApp-Image-2023-03-01-at-21.43.57-2.jpeg"
                        alt="Deskripsi gambar"
                        style={{ width: '100%' }}
                    />
                    <div className="text">Versi 2</div>
                </div>

                <a className="prev" onClick={() => setNaikSatu(naikSatu-1)}>&lt;</a>
                <a className="next" onClick={() => setNaikSatu(naikSatu+1)}>&gt;</a>

            </div>   
        </div>

    )
}

export default MultiImage
