import CetakImageSlide from "./CetakImageSlide";

function CetakImage({LipatDua, getnama1, getnama2}){
    return(
        <div className="undangancetakWrapper">

        <div className="undangancetakbox">
            <div className="untukpaddingcetakbox">
                <div>
                    <div className="undangancetakboxImg">
                        {/* <img src="./imageGroup/undangancetak/LIPAT-2-TEMA-25.jpg"></img> */}
                        <CetakImageSlide isicurrent={LipatDua} nama1={getnama1} nama2={getnama2}/>
                    </div>
                </div>

                <div>
                    <div className="undangancetakboxtext">
                        <h1>KODE : CETAK</h1>
                    </div>
                    <div>
                        <p>Ukuran kertas A4</p>
                        <p>Jenis Kertas : Jasmin Glitter 200 gsm</p>
                        <p>Design Full color</p>
                    </div>
                </div>
            </div>
        </div>


        </div>
    )
}
export default CetakImage;