import React from 'react'

const GaleriEmpat = () => {
    const kosong = "/PreviewItem/"
    const idImage = `/PreviewItem/#pre`
    const alamat = "./imageGroup/Preview/WhatsApp-Image-2023-03-01-at-21.43.57-2.jpeg"
    return (
        <div>
            <div>              
                <a href={`#${idImage}`} className='imgCH'>
                    <img id='gambarTarget' src={alamat}></img>
                </a>
                <div className='overlay' id={idImage}>
                    <div className="over-inside">
                    <a href={`#${kosong}`} className='KeluarImage'>CLOSE</a>
                    <img src={alamat}></img>
                    <div id ={`#${kosong}`}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GaleriEmpat


// {
//     return (
//       <div className="filterCardWrapper">
//               <div>
//                   <a href='#gambarTujuan' className='imgCardFilterInsta'>
//                       <img id='gambarTarget' src="./imageGroup/Preview/WhatsApp-Image-2023-03-01-at-21.43.57-2.jpeg"></img>
//                   </a>
//                   <div className='overlay' id='gambarTujuan'>
//                   <a href={`#${kosong}`} className='KeluarImage'>CLOSE</a>
//                       <img src="./imageGroup/filter/conten.jpg"></img>
//                       <div id ={`#${kosong}`}></div>
//                   </div>
//               </div>
//           </div>
//     )
//   }