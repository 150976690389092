import React, { useEffect, useState } from 'react'
import { Prev } from 'react-bootstrap/esm/PageItem';


const SesiSepuluh = () => {

    


    let [tampilKonten, setTampilKonten] = useState(false)
    function gantiTampil(x) {
        setTampilKonten(x)
        console.log(x)
    }

    let lihatIsi = tampilKonten;
    useEffect(() => {
        const getTpmbol = document.getElementById("display-s10")
        if (lihatIsi == true) {
            getTpmbol.style.display = "block"
        } else if (lihatIsi == false) {
            getTpmbol.style.display = "none"
        }

    }, [lihatIsi])
    return (
        <div className='sesi10-sesi11'>
            <div className='sesi10-container-outer'>
                <div className='sesi10-outer-content'>
                    <div className='gift-circle'>
                        <div className='gift-rectangle'>

                        </div>
                    </div>
                    <h1 className='sesi10-outer-content-h1'>Wedding Gift</h1>
                    <div className='sesi10-outer-content-p'>
                        <p>Doa restu Anda merupakan karunia yang sangat berarti bagi kami. Dan jika memberi adalah ungkapan tanda kasih Anda,</p>
                        <p>Anda dapat memberi kado secara cashless.</p>
                    </div>
                    <button className='btn-s10-gift' onClick={() => gantiTampil(!tampilKonten)}>Weeding Gift

                    </button>
                </div>

                <div id='display-s10' className='sesi10-container-inner'>
                    <div>
                        <div className='logo-bank-wrapper'>
                            <img className='logo-bank' src='./imageGroup/Preview/342-3424868_bca-png-transparent-background-logo-bank-bca-png-3-768x274.png'>
                            </img>
                        </div>

                        <p>Bank BCA</p>
                        <p>No. Rekening 123456789</p>
                        <p>a.n Shintia Putri</p>
                        <button className='bton-sesi10'>Saling Nomor Rekening</button>
                        <div className='hr-wrapper-s10'>
                            <hr></hr>
                        </div>
                    </div>

                    <div>
                        <div className='logo-bank-wrapper'><img className='logo-bank' src='./imageGroup/Preview/LOGO-MANDIRI-1-2.png'></img>

                        </div>

                        <p>Bank Mandiri</p>
                        <p>No. Rekening 123456789</p>
                        <p>a.n Bhima Putra</p>
                        <button className='bton-sesi10'>Saling Nomor Rekening</button>
                        <div className='hr-wrapper-s10'>
                            <hr></hr>
                        </div>
                    </div>
                    <div className='sesi10-form'>
                        <h2>Konfirmasi Kirim Hadiah</h2>
                        <form className='form-kirim' action="/action_page.php" target="_blank">

                            <label htmlFor="fname">Nama</label><br></br>
                            <input className='isitextform' type="text" id="fname" name="fname"></input><br></br>

                            <label htmlFor="fjumlah">Jumlah</label><br></br>
                            <input className='isitextform' type="text" id="fjumlah" name="fjumlah"></input><br></br>

                            <p>Rekening Tujuan</p>
                            <div>
                                <input type="radio" id="html" name="fav_language" value="HTML"></input>
                                <label htmlFor="html">BRI</label><br></br>
                            </div>

                            <div>
                                <input type="radio" id="css" name="fav_language" value="CSS"></input>
                                <label htmlFor="css">MANDIRI</label><br></br>
                            </div>

                            <div>
                                <input type="radio" id="javascript" name="fav_language" value="JavaScript"></input>
                                <label htmlFor="javascript">BCA</label>
                            </div>

                            <div>
                                <input type="radio" id="css" name="fav_language" value="CSS"></input>
                                <label htmlFor="css">BNI</label><br></br>
                            </div>

                            <input className='btnform' type="submit" value="Reservasi Via Whatsapp"></input>

                        </form>
                    </div>


                </div>
            </div>


            <div className='sesi11-wrapper'>
                <div className='sesi11-container'>
                    <h1>HEALTH PROTOCOLS</h1>
                    <div className='prtocol-wrapper'>
                        <div className='protocol-s11'>
                            <img src='./imageGroup/Preview/2-2-1.png'></img>
                            <p>Memakai Masker</p>
                        </div>
                        <div className='protocol-s11'>
                            <img src='./imageGroup/Preview/3-1.png'></img>
                            <p>Mencuci Tangan</p>
                        </div>
                        <div className='protocol-s11'>
                            <img src='./imageGroup/Preview/1-2-1.png'></img>
                            <p>Menajaga Jarak</p>
                        </div>
                        <div className='protocol-s11'>
                            <img src='./imageGroup/Preview/4-1.png'></img>
                            <p>Cek Suhu Tubuh</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default SesiSepuluh
