import React, { useEffect } from 'react'
import { useState } from 'react';

const ImageGaleri = () => {
    let [naikSatu, setNaikSatu] = useState(0);
    
    useEffect(() => {
        const imageOne = document.getElementsByClassName('img-1')
        for (let i = 0; i < imageOne.length; i++) {
            imageOne[i].style.display = "none";  
          }
        imageOne[naikSatu].style.display = "block";
        function addArrayImage (){
            if(naikSatu == imageOne.length-1){
                setNaikSatu(0);
            } else {
                setNaikSatu(naikSatu+1);
            }
        }  
        const intervalId = setInterval(() => {
            addArrayImage();
        }, 7000);

        // Bersihkan interval saat komponen dibersihkan
        return () => clearInterval(intervalId);
    },[naikSatu])


    return (
        <div className='IG-wrapper'>

            <div className='IG-container'>

                <div className='IG-withimage1'>
                    <img className='img-1' src='./imageGroup/Preview/WhatsApp-Image-2023-03-01-at-21.43.55-1.jpeg'></img>
                    <img className='img-1' src='./imageGroup/Preview/WhatsApp-Image-2023-03-01-at-21.43.56-1.jpeg'></img>
                    <img className='img-1' src='./imageGroup/Preview/ls2.jpg'></img>
                    <img className='img-1' src='./imageGroup/Preview/WhatsApp-Image-2023-03-01-at-21.43.56.jpeg'></img>
                </div>


            </div>
        </div>
    )
}

export default ImageGaleri
